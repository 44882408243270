import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '29c064b8-b5a4-4286-9f19-74e1021ee7e9',
    development: 'c5a84076-af99-4a3d-975e-1a919eb49829',
  },
}

export default {
  name: {
    nb: 'Ventilasjonsteknikk',
  },
  slug: 'ventilasjon',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-TSSK3TZ',
  },
  assets: {
    logo: '/themes/ventilasjon/images/logo.svg',
    favicon: '/themes/ventilasjon/favicon.ico',
  },
  styles: [
    '/themes/ventilasjon/css/ventilasjon.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Ventilasjonsteknikk vg2</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til Ventilasjonsteknikk vg2</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Begreper',
        to: '/concepts/begreper',
      },
      {
        title: 'Yrkesoppgaver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler',
      },
      {
        title: 'Dokumentasjon',
        to: '/files/dokumentasjon',
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
      },
    ],
    nn: [
      {
        title: 'Fagomgrep',
        to: '/concepts/begreper',
      },
      {
        title: 'Yrkesoppgåver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'Fagartiklar',
        to: '/articles/fagartikler',
      },
      {
        title: 'Dokumentasjon',
        to: '/files/dokumentasjon',
      },
      {
        title: 'Lærarens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
      },
    ],
  },
}
